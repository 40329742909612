import Logo from "../components/logo";
import {QRCodeSVG} from 'qrcode.react';

const BeginnersGuidetoBtcNftPage = () => {
  return (
    <div className="flex flex-col items-center py-10 px-5">
      <Logo />
      <p className="text-white text-3xl font-bold mt-4" style={{ maxWidth: 1000, width: '100%' }}>Getting Started with BTC/NFTs</p>
      <div style={{ maxWidth: 1000, width: '100%' }}>
        <p className="text-white text-2xl mt-2">
          All digital assets are incredibly risky, and it’s possible to lose everything. This includes Bitcoin.
          
          Instead of the price, focus on the fundamentals.
        </p>
        <ul className="list-disc ml-4 text-white text-2xl">
          <li>For BTC, learn about money. </li>
          <li>For NFTs, if you like the art, buy it.</li>
        </ul>
        <p className="text-white text-2xl">
          <br />
          The most important investment is taking the time to understand it. The average person takes 100 hours to learn to drive. It takes about 25 to 50 hours to learn the fundamentals of Bitcoin. <br/><br/>One idea to get started: Everything you think you own is simply an entry in someone else’s database. 
          <br />
          <p className="text-white text-3xl font-bold mt-4" style={{ maxWidth: 1000, width: '100%' }}>Getting Started with Bitcoin</p>
        </p>
        <div className="flex items-start mt-6">
          <div style={{ backgroundColor: 'white', padding: 8, borderRadius: 12 }}>
            <QRCodeSVG 
              value="https://introduction-to-bitcoin.iykyk.plus/" 
              size={240}
            />
          </div>
          <div className="ml-12">
            <p className="text-white text-2xl">
              If after learning more you’re interested to become involved, dollar-cost-averaging about the amount you might spend on a nice meal every week makes sense, while continuing to learn.
              <br /><br />
              Bitcoin is a 5 to 10 year bet, assuming the current banking failure doesn’t escalate into a global catastrophe. If it does, understanding how to securely self-custody your own assets could be one of the most important things you ever learn.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BeginnersGuidetoBtcNftPage;
