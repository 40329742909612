import Logo from "../components/logo"
import {QRCodeSVG} from 'qrcode.react';

const BitcoinRabbitHolePage = () => {
  return (
    <div 
      className="flex flex-col items-center py-10 px-5"
    >
      <Logo />

      <img 
        // TODO
        src="/toby-rabbithole-fullsize.png"
        className="mt-2"
        style={{ height:500 }}
      />

      <p className="text-white text-3xl font-bold mt-4" style={{ maxWidth: 1000, width: '100%' }}>Bitcoin Rabbit Hole - Toby’s Rabbit Hole</p>
      <p className="text-white text-2xl mt-4" style={{ maxWidth: 1000, width: '100%' }}>
        The multifaceted nature of Bitcoin, encompassing both complex technology and profound philosophy, often sends inquisitive minds spiralling down an intriguing rabbit hole. Questions such as "What is Bitcoin?", "Why was Bitcoin created?", "How does Bitcoin work?", and "Who created Bitcoin?" each open up a labyrinth of exploration and understanding.
        <br/><br/>
        This depth is mirrored in the hidden symbolism of the rabbit design in this piece. It's a nod to those “who know”, who have tumbled down the Bitcoin rabbit hole and engaged in the vibrant discussions and debates about the nature of Bitcoin and money. It's a shared understanding, an insider's knowledge.
        <br/><br/>
        The creator of this piece, Tobias Brunsdon, is a master of the intricate art form known as zentangle. Each of his works, painstakingly crafted by hand, reflects an extraordinary level of detail. His larger pieces can require up to 50 hours of focused attention to complete. Tobias describes a trance-like state that envelops him while he works, a mesmerising experience that is often echoed in the viewer's engagement with the art.
        <br/><br/>
        Given the depth and intricacy inherent to Bitcoin, Tobias was an ideal collaborator to breathe life into this concept. His artistry, much like the complex underpinnings of Bitcoin, beckons viewers to delve deeper, to traverse the rabbit hole, and to engage with the layers of meaning beneath the surface.
      </p>
      <div className="mt-6" style={{ backgroundColor: 'white', padding: 8, borderRadius: 12 }}>
        <QRCodeSVG 
          // TODO
          value="https://www.instagram.com/tobias_illustrations/" 
          size={240}
        />
      </div>
    </div>
  )
}

export default BitcoinRabbitHolePage;