import _ from "lodash"
import BitcoinLivesPage from './pages/bitcoin-lives';
import RootNodePage from './pages/root-node';
import BitcoinRabbitHolePage from "./pages/bitcoin-rabbit-hole-page"
import VendingMachinePage from "./pages/vending-machine-page"
import BeginnersGuidetoBtcNftPage from "./pages/beginners-guide-to-btc-nft"
import IntroducingRabbitholePage from "./pages/introducing-rabbithole"
import IntroductionToBitcoinPage from "./pages/introduction-to-bitcoin"

export default function App() {

  // https://docs.google.com/document/d/1SDCUkj8qppRKpu4v8GRbJJHzGio6S6AadtNYoLaF8Cc/edit
  const pageRouting = [
    {
      page: 'btc-lives',
      domain: 'btc-lives.iykyk.plus',
      component: BitcoinLivesPage
    },
    {
      page: 'root-node',
      domain: 'root-node.iykyk.plus',
      component: RootNodePage
    },
    {
      page: 'tobys-rabbit-hole',
      domain: 'tobys-rabbit-hole.iykyk.plus',
      component: BitcoinRabbitHolePage
    },
    {
      page: 'vending-machine',
      domain: 'vending-machine.iykyk.plus',
      component: VendingMachinePage
    },
    {
      page: 'beginners-guide-to-btc-nft',
      domain: 'beginners-guide-to-btc-nft.iykyk.plus',
      component: BeginnersGuidetoBtcNftPage
    },
    {
      page: 'introducing-rabbithole',
      domain: 'introducing-rabbithole.iykyk.plus',
      component: IntroducingRabbitholePage
    },
    {
      page: 'introduction-to-bitcoin',
      domain: 'introduction-to-bitcoin.iykyk.plus',
      component: IntroductionToBitcoinPage
    }
  ]

  let pageToShow

  // Try get 'page' param first
  const urlParams = new URLSearchParams(window.location.search);
  const pageParam = urlParams.get("page");
  console.log(pageParam)
  if (pageParam) {
    pageToShow = _.find(pageRouting, { page: pageParam })
  }

  // If no 'page' param, we'll use the domain/subdomain
  const domain = window.location.hostname;
  console.log(domain);
  if (!pageParam) {
    pageToShow = _.find(pageRouting, { domain: domain })
  }

  if (pageToShow) {
    return (
      <>
        <pageToShow.component />
      </>
    )
  }
  
  return(<p style={{color:"white"}}>Unknown</p>)
}