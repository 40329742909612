import Logo from "../components/logo"
import {QRCodeSVG} from 'qrcode.react';

const IntroductionToBitcoinPage = () => {
  return (
    <div 
      className="flex flex-col items-center py-10 px-5"
    >
      <Logo />

      <img 
        // TODO
        src="/introduction-to-bitcoin-hero.png"
        className="mt-2"
        style={{ height:200 }}
      />

      <p className="text-white text-3xl font-bold mt-4" style={{ maxWidth: 500, width: '100%' }}>Getting Started with Bitcoin</p>

      <div className="mt-6" style={{ maxWidth: 500, width: '100%' }}>
        <ul className="list-disc ml-4 text-white text-md">
          <li><a target="_blank" className="text-blue-600 dark:text-blue-500 hover:underline" href="https://vijayboyapati.medium.com/the-bullish-case-for-bitcoin-6ecc8bdecc1">The Bullish Case for Bitcoin </a></li>
          <li><a target="_blank" className="text-blue-600 dark:text-blue-500 hover:underline" href="https://www.whatbitcoindid.com/the-beginners-guide-to-bitcoin">Podcast Introductions</a></li>
          <li><a target="_blank" className="text-blue-600 dark:text-blue-500 hover:underline" href="https://www.amazon.co.uk/Bitcoin-Standard-Decentralized-Alternative-Central/dp/1119473861/ref=sr_1_1?crid=M4M7V0TBWLBS&keywords=bitcoin+standard&qid=1683830519&sprefix=bitcoin+standard%2Caps%2C92&sr=8-1">The Bitcoin Standard</a></li>
          <li><a target="_blank" className="text-blue-600 dark:text-blue-500 hover:underline" href="https://bitcoinandthebible.com/posts/startupguide/">Practical Guide to Getting Started</a></li>
          <li><a target="_blank" className="text-blue-600 dark:text-blue-500 hover:underline" href="https://www.swanbitcoin.com/">We use and trust Swan Bitcoin for Bitcoin purchases</a></li>
          <li><a target="_blank" className="text-blue-600 dark:text-blue-500 hover:underline" href="https://keys.casa/">Casa is the best, most secure self-custody solution.</a></li>
        </ul>
      </div>
      <br/>
      <p className="text-white text-2xl font-bold mt-4" style={{ maxWidth: 500, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>QR Code Link To This Page</p>

      <div className="mt-6" style={{ backgroundColor: 'white', padding: 8, borderRadius: 12 }}>
        <QRCodeSVG 
          // TODO
          value="https://introduction-to-bitcoin.iykyk.plus/" 
          size={240}
        />
      </div>
    </div>

  )
}

export default IntroductionToBitcoinPage;