import Logo from "../../components/logo"
import {QRCodeSVG} from 'qrcode.react';

const BitcoinLivesPage = () => {
  return (
    <div 
      className="flex flex-col items-center py-10 px-5"
    >
      <Logo />
      <br/>
      <img 
        // TODO
        src="/btc-lives-hero.png"
        className="mt-2"
        style={{ height:200 }}
      />

      <p className="text-white text-3xl font-bold mt-4" style={{ maxWidth: 1000, width: '100%' }}>Bitcoin Lives</p>
      <p className="text-white text-2xl mt-4" style={{ maxWidth: 1000, width: '100%' }}>
        This creation reflects the profound revelations often experienced
        when learning about Bitcoin, and the questions that arise. What is money?
        Where does it come from? Who controls it? Do we really own the money we think we own?
        <br/><br/>
        People rarely think about or question the nature and concept of money. However
        understanding money’s origin and history is indispensable when seeking to understand Bitcoin.
        <br/><br/>
        Therefore, I offer sunglasses as a metaphor, the choice is there to see the truth.
        <br/><br/>
        We are selling 50 copies of the associated Bitcoin Lives NFT animation, each for 0.025 ETH/£36.<br />
        There are currently 50 available.
      </p>
      
      <div className="mt-6" style={{ backgroundColor: 'white', padding: 8, borderRadius: 12 }}>
        <a href="https://exhibition.iykyk.plus/mint/?contract=0xB3d734Ced6Dad731792268421DB74bC609b430d7">
          <QRCodeSVG 
            // TODO
            value="https://exhibition.iykyk.plus/mint/?contract=0xB3d734Ced6Dad731792268421DB74bC609b430d7" 
            size={240}
        />
        </a>
      </div>
    </div>
  )
}

export default BitcoinLivesPage;