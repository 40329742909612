import Logo from "../../components/logo"
import {QRCodeSVG} from 'qrcode.react';

const RootNodePage = () => {
  return (
    <div 
      className="flex flex-col items-center py-10 px-5"
    >
      <Logo />

      <img 
        // TODO
        src="/root-node-hero.png"
        className="mt-2"
        style={{ height:300 }}
      />

      <p className="text-white text-3xl font-bold mt-4" style={{ maxWidth: 1200, width: '100%' }}>Root Node</p>
      <p className="text-white text-2xl mt-4" style={{ maxWidth: 1200, width: '100%' }}>
        While strolling through the city streets, my husband shared a thought which I found profound. "The world of trees extends far beyond what we see above ground. The real magic happens beneath our feet, in the complex network of roots that enable communication and even exchanging nutrients between trees."
        <br /><br />
        This thought sparked an intriguing correlation in my mind - the unseen world of tree roots as a metaphor for the Bitcoin network. Both are largely invisible yet play a crucial role in their respective ecosystems.
        <br /><br />
        Each tree in a forest is a part of a vast network, communicating and sharing resources through its roots. Similarly, within the realm of Bitcoin, each node can be compared to a tree in the forest of the blockchain, exchanging information within the network.
        <br /><br />
        Just as tree roots are fundamental, offering structural support and playing a crucial role in the absorption of water and nutrients, each block in the blockchain serves an equally vital function. They underpin our financial system, providing a pathway to financial autonomy and a refuge from excessive surveillance and centralized control.
        <br /><br />
        So, the next time you find yourself admiring a tree or engaging in a Bitcoin transaction, take a moment to appreciate the unseen yet essential networks operating beneath the surface.
      </p>
    </div>
  )
}

export default RootNodePage;