import Logo from "../components/logo";
import {QRCodeSVG} from 'qrcode.react';

const VendingMachinePage = () => {
  return (
    <div 
      className="flex flex-col items-center py-10 px-5"
    >
      <Logo />

      <div className="flex flex-row mt-2" style={{ maxWidth: 1000, width: '100%' }}>
        <img 
          src="/smartcontract.jpeg"
          style={{ height:600 }}
        />

        <div className="ml-6">
          <p className="text-white text-3xl font-bold">Vending Machine</p>
          <p className="text-white text-1xl mt-4">
            NFTs revolutionise the concept of digital ownership, infusing virtual assets with attributes traditionally associated with their physical counterparts - scarcity, uniqueness, and verifiable ownership. 
            <br /><br />
            The precise timeline may be uncertain, but the trajectory is clear: the value of trustless, immutable, and decentralised digital assets is poised to increase in importance and significance.
            <br /><br />
            Understanding NFTs and the smart contracts that govern them can be challenging. To illuminate this, computer scientist Nick Szabo draws an analogy with a vending machine. Just as a vending machine dispenses a product when the correct payment is inserted, smart contracts automatically execute the terms of an agreement when certain pre-established conditions are met.
            <br /><br />
            This artwork, crafted by Cameron Burns, features his signature character, the space suit man. This figure serves as a metaphor for the collective exploration into the uncharted territories of NFTs and blockchain technology.
          </p> 
        </div>
      </div>
    </div>
  )
}

export default VendingMachinePage;
