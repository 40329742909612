import Logo from "../components/logo"
import {QRCodeSVG} from 'qrcode.react';

const IntroducingRabbitholePage = () => {
  return (
    <div 
      className="flex flex-col items-center py-10 px-5"
    >
      <Logo />

      {/*
      <img 
        src="/toby-rabbit-hole.png"
        className="mt-2"
        style={{ height:200 }}
      />
    */}

      <p className="text-white text-2xl font-semibold mt-4 text-center" style={{ maxWidth: 500, width: '100%' }}>Rabbit Hole Menu</p>


      <div style={{ maxWidth: '100%', width: '100%' }}>
        <table className="min-w-full divide-y divide-gray-700" style={{ maxWidth: 500, width: '100%' }}>
          <thead>
            <tr>
              <th scope="col" className="px-3 py-3.5 pr-3 text-left text-xl font-semibold text-white">
                Cost
              </th>
              <th scope="col" className="py-3.5 pl-4 text-left text-xl font-semibold text-white sm:pl-0">
                Title
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-xl font-semibold text-white">
                Description
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-xl font-semibold text-white">
                
              </th>
              {/*
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                QR Code
              </th>
              */}
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-600">
            {
              [

                {
                  title: `Wallet Setup`,
                  description: `Interested in Bitcoin/NFTs but don’t have a wallet? We’ll help you set one up`,
                  cost: `£50`,
                  qrCode: ``,
                  image: `/bitcoin-wallet.jpg`
                },
                {
                  title: `iykyk+ pass NFT\n8,888 Available`,
                  description: `The core NFT to be purchased to become a iykyk+ community member.\nSecure your place in the 8,888 passes available, and automatically receive airdropped art.`,
                  cost: `0.025 ETH\n£37`,
                  qrCode: ``,
                  image: `/iykyk-pass-nft.png`
                },
                {
                  title: `Rabbit Hole NFT\n450 Available`,
                  description: `Create and mint your own Rabbit Hole NFT.`,
                  cost: `0.025 ETH\n£37`,
                  qrCode: ``,
                  image: `/rabbit-hole.png`
                },
                {
                  title: `Rabbit Hole NFT VIP\n50 Available`,
                  description: `Create and mint your own Rabbit Hole NFT, and receive a pair of sunglasses and a special Rabbit Hole NFT.`,
                  cost: `0.05 ETH\n£74`,
                  qrCode: ``,
                  image: `/rabbit-hole-sunglasses.png`
                },
                {
                  title: `Vending Machine\n50 Available`,
                  description: `Own a piece of the Vending Machine art piece, depicting smart contracts as vending machines.`,
                  cost: `0.025 ETH\n£37`,
                  qrCode: ``,
                  image: `/vending-machine.png`
                },
                /*
                {
                  title: `“Rabbit Hole”\n40 Available`,
                  description: `After you’ve experienced “Rabbit Hole”, buy a piece of it to keep forever.`,
                  cost: `0.025`,
                  qrCode: ``,
                  image: `https://placehold.co/200x200/png`
                },
                */
                {
                  title: `Bitcoin Lives\n50 Available`,
                  description: `After seeing the truth, take a piece of it home with you, secured on the blockchain, along with your bonus Bitcoin 3d printed badge (limited stocks)`,
                  cost: `0.025 ETH\n£37`,
                  qrCode: ``,
                  image: `/root-node.png`
                },
                {
                  title: `“Rabbit Hole”\nby Trippy Everything`,
                  description: `Own an 8 second piece of Trippy Everything's iconic work, "Rabbit Hole" specially commissioned for the iykyk+ Rabbit Hole exhibition.`,
                  cost: `0.025 ETH\n£37`,
                  qrCode: ``,
                  image: `/trippy-everything.png`
                },
                /*
                {
                  title: `Digital Calligraphy\n50 Available`,
                  description: `TBC…!`,
                  cost: ``,
                  qrCode: ``,
                  image: `https://placehold.co/200x200/png`
                },
                */
              ].map((item, index) => (
                <tr key={`${index}`}>
                  <td 
                    className="px-3 py-41pr-3 text-2xl font-semibold text-white"
                    style={{ width: 150 }}
                    dangerouslySetInnerHTML={{__html: item.cost.replace(/\n/g, "<br />")}}
                  />
                  <td 
                    className="py-1 pl-4 text-2xl font-medium text-white"
                    dangerouslySetInnerHTML={{__html: item.title.replace(/\n/g, "<br />")}}
                  />
                  <td 
                    className="px-3 py-1 text-2xl text-gray-300"
                    dangerouslySetInnerHTML={{__html: item.description.replace(/\n/g, "<br />")}}
                  />
                  <td className="px-3 py-1 text-2xl text-gray-300" style={{ height: 100, width: 100 }}>
                    <img src={item.image} style={{ borderRadius: 8 }} />
                  </td>
                  {/*
                  <td className="px-3 py-1 text-2xl text-gray-300">
                    <QRCodeSVG 
                      value={item.qrCode}
                      size={80}
                    />
                  </td>
                */}
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>

    </div>
  )
}

export default IntroducingRabbitholePage;