const Logo=() => {
  return (
    <svg version="1.1" x="0px" y="0px"
	 viewBox="0 0 950 350" fill="white" height={50}>
<g>
	<path d="M62.5,261.2l24.3-138h30.9l-24.3,138H62.5z M93.2,100.4c-3-3.7-4-8.2-3.1-13.4c0.9-5.2,3.5-9.6,7.8-13.2
		c4.3-3.6,9-5.4,14.2-5.4c5.2,0,9.3,1.8,12.4,5.4c3.1,3.6,4.2,8,3.2,13.2c-0.9,5.2-3.6,9.7-7.9,13.4c-4.4,3.7-9.2,5.6-14.4,5.6
		C100.3,105.9,96.2,104.1,93.2,100.4z"/>
	<path d="M131.9,302.2l5.1-29c8.2,0,14.7-1.5,19.6-4.5c4.9-3,9.7-8.1,14.3-15.3l3.8-5.6L137,123.2h31.8l19.5,74.2l5.7,21.5
		l13.4-23.1l42.2-72.6h31.2l-88.4,144.1C178.1,290.6,158,302.2,131.9,302.2z"/>
	<path d="M276.1,261.2l32.3-183.5h30.9l-19.5,110.4l65.8-64.9h38.2l-65,62l50.7,76H372l-35.6-54.5l-23.8,22.7l-5.6,31.8H276.1z"/>
	<path d="M432.2,302.2l5.1-29c8.2,0,14.7-1.5,19.6-4.5c4.9-3,9.7-8.1,14.3-15.3l3.8-5.6l-37.7-124.6h31.8l19.5,74.2l5.7,21.5
		l13.4-23.1l42.2-72.6h31.2l-88.4,144.1C478.4,290.6,458.2,302.2,432.2,302.2z"/>
	<path d="M576.3,261.2l32.3-183.5h30.9l-19.5,110.4l65.8-64.9h38.2l-65,62l50.7,76h-37.6l-35.6-54.5l-23.8,22.7l-5.6,31.8H576.3z"/>
	<path d="M796.6,172.1l8.7-49.4h-50.2l4.4-25.1h50.2l8.8-49.9h27.3l-8.8,49.9h50.5l-4.4,25.1h-50.5l-8.7,49.4H796.6z"/>
</g>
</svg>
  )
}
export default Logo